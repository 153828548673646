import { FC } from "react";
import { ViewCss } from "../models/StyleModel";
import data from "../resume_data.json";

const colors = ["#F69898", "#F6F698", "#B6F698", "#98F5F6", "#E898F6"];

const Projects: FC<ViewCss> = (props) => {
  return (
    <section id="skills" className={`resume ${props.css}`}>
      <h3 className="title">Projects</h3>
      <article className="resume-lines">
        <section className="resume-line line-left">
          {data.projects.map((val, index) => {
            return (
              <article className="line-down">
                <div className="date active">{val.company}</div>
                <h5 className="name">{val.project_name}</h5>
                <h6 className="company">{val.domain}</h6>
                <div>
                  {val.tech_stack.map((v) =>
                    v.length < 12 ? (
                      <>
                        <span className="badge" style={{
                            backgroundColor: colors[Math.floor(Math.random() * 5)]
                        }}>{v}</span>&nbsp;
                      </>
                    ) : (
                      <>
                        <br />
                        <span className="badge" style={{
                            backgroundColor: colors[Math.floor(Math.random() * 5)]
                        }}>{v}</span>&nbsp;
                      </>
                    )
                  )}
                </div>
                <p>{val.description}</p>
              </article>
            );
          })}
        </section>
      </article>
    </section>
  );
};

export default Projects;
