import { FC } from "react";
import { ViewCss } from "../models/StyleModel";
import data from "../resume_data.json";

const Educations: FC<ViewCss> = (props) => {
  return (
    <section id="skills" className={`resume ${props.css}`}>
      <h3 className="title">Education</h3>
      <article className="resume-lines">
        <section className="resume-line line-left">
          {data.educations.map((val, index) => {
            return (
              <article className="line-down">
                <div className="date active">University:<b> {val.university}</b></div>
                <h5 className="name">{val.subject}</h5>
                <h6 className="company">{val.passout_year}</h6>
                <p>Institute: <b> {val.school}</b></p>
              </article>
            );
          })}
        </section>
      </article>
    </section>
  );
};

export default Educations;
