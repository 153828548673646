/* eslint-disable react/jsx-no-target-blank */
import { FC } from "react";
import data from "../resume_data.json";
import "../ribbon.css";
import myImage from '../my_photo.jpeg';

const Profile: FC = () => {
  return (
    <section id="presentation" className="profile">
      <div className="profile-background">
        <div className="ribbon-1">{data.my_info.fullstack_developer}</div>
        <div className="ribbon-2">{data.my_info.backend_developer}</div>
        <div className="ribbon-3">{data.my_info.frontend_engineer}</div>
      </div>
      <div>
        <div className="profile-image">
          <img src={myImage} alt="Sangram Badi" />
        </div>
        <h1 className="profile-name" id="nombre">
          {data.my_info.name}
        </h1>
        <h2 className="profile-profession">Software Engineer / Developer</h2>
        <div className="profile-social" id="profile-social">
          {data.links.map((value, index) => {
            return (
              value.show && (
                <a
                  href={value.href}
                  className={value.icon}
                  key={index}
                  target="_blank"
                />
              )
            );
          })}
        </div>
      </div>
      <div className="profile-buttons">
        <a href="/myCV.txt" download>
          <i className="fas fa-envelope">&nbsp;{data.my_info.email}</i>
        </a>
        <a href="#contact">
          <i className="fas fa-map-marker">&nbsp;{data.my_info.current_city}, {data.my_info.country}</i>
        </a>
      </div>
    </section>
  );
};

export default Profile;
