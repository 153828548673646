import { FC } from "react";
import { ViewCss } from "../models/StyleModel";
import data from "../resume_data.json";

const WorkHistory: FC<ViewCss> = (props) => {
  return (
    <section id="skills" className={`resume ${props.css}`}>
      <h3 className="title">Work History</h3>
      <article className="resume-lines">
        <section className="resume-line line-left">
          {data.work_hostory.map((val, index) => {
            return (
              <article className="line-down">
                <div className="date active">{val.year}</div>
                <h5 className="name">{val.designation}</h5>
                <h6 className="company">{val.company}</h6>
                <p>{val.description}</p>
              </article>
            );
          })}
        </section>
      </article>
    </section>
  );
};

export default WorkHistory;
