import { FC } from "react";
import { ViewCss } from "../models/StyleModel";
import data from "../resume_data.json";

const AboutMe: FC<ViewCss> = (props) => {
  return (
    <section id="about" className={`about ${props.css}`}>
      <article className="about-aboutMe">
        <h3 className="title">About Me</h3>
        <div className="line-left">
          <p>{data.summery}</p>
        </div>
      </article>
      <article className="about-services">
        <h3 className="title">Skills</h3>
        <div className="line-left">
          {data.skills.map((skill, index) => {
            return (
              <article className="line-down">
                <div className="fas">
                  <h4>{skill.label}</h4>
                </div>
                {skill.values.map((val, idx) => {
                  return <p>{val}</p>;
                })}
              </article>
            );
          })}
        </div>
      </article>
    </section>
  );
};

export default AboutMe;
