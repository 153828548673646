/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import AboutMe from "./components/AboutMe";
import Awards from "./components/Awards";
import Educations from "./components/Educations";
import Profile from "./components/Profile";
import Projects from "./components/Projects";
import WorkHistory from "./components/WorkHistory";

enum Options {
  ABOUT_ME,
  JOBS,
  PROJECTS,
  AWARDS,
  EDUCATIONS,
}

function App() {

  const [aboutMe, setAboutMe] = useState(true);
  const [jobs, setJobs] = useState(false);
  const [projects, setProjects] = useState(false);
  const [awards, setAwards] = useState(false);
  const [educations, setEducations] = useState(false);

  const clearAll = () => {
      setAboutMe(false);
      setJobs(false);
      setProjects(false);
      setAwards(false);
      setEducations(false);
  }

  const handleClick = (value: Options) => {
    if(value === Options.ABOUT_ME) {
      clearAll();
      setAboutMe(true);
    }
    if(value === Options.JOBS) {
      clearAll();
      setJobs(true);
    }
    if(value === Options.PROJECTS) {
      clearAll();
      setProjects(true);
    }
    if(value === Options.AWARDS) {
      clearAll();
      setAwards(true);
    }
    if(value === Options.EDUCATIONS) {
      clearAll();
      setEducations(true);
    }
  }

  return (
    <>
      <div className="background gradient">
      </div>
      <div className="page">
        <header>
          <nav>
            <a className={aboutMe ? "selected" : ""} id="getAbout" onClick={() => handleClick(Options.ABOUT_ME)}>
              <span className="fas fa-user"></span>
              <span className="link">About</span>
            </a>
            <a className={jobs ? "selected" : ""} id="getJobs"  onClick={() => handleClick(Options.JOBS)}>
              <span className="fas fa-building"></span>
              <span className="link">JOBS</span>
            </a>
            <a className={ projects ? "selected" : ""} id="geProjects"  onClick={() => handleClick(Options.PROJECTS)}>
              <span className="fas fa-tasks"></span>
              <span className="link">Projects</span>
            </a>
            <a className={ awards ? "selected" : ""} id="getAwards" onClick={() => handleClick(Options.AWARDS)}>
              <span className="fas fa-trophy"></span>
              <span className="link">Awards</span>
            </a>
            <a className={ educations ? "selected" : ""} id="getEducation" onClick={() => handleClick(Options.EDUCATIONS)}>
              <span className="fas fa-university"></span>
              <span className="link">Education</span>
            </a>
          </nav>
        </header>
        <main>
          <Profile />
          <AboutMe css={aboutMe ? 'view' : ''}/>
          {/* <Skills css={skills ? 'view' : ''}/> */}
          <WorkHistory css={jobs ? 'view' : ''}/>
          <Projects css={projects ? 'view' : ''}/>
          <Awards css={awards ? 'view' : ''}/>
          <Educations css={educations ? 'view' : ''}/>
        </main>
      </div>
    </>
  );
}

export default App;
