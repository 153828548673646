import { FC } from "react";
import { ViewCss } from "../models/StyleModel";
import data from "../resume_data.json";

const Awards: FC<ViewCss> = (props) => {
    return <section id="skills" className={`resume ${props.css}`}>
    <h3 className="title">Awards</h3>
    <article className="resume-lines">
      <section className="resume-line line-left">
        {data.awards.map((val, index) => {
          return (
            <article className="line-down">
              <div className="date active">{val.company}</div>
              <h5 className="name">{val.name}</h5>
              <p>{val.description}</p>
            </article>
          );
        })}
      </section>
    </article>
  </section>
}

export default Awards;